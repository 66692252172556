import React from 'react'

import Layout from '../components/layout';
import AsyncDownload from '../components/async-download';
/*
import RoundedIconButton from "../components/rounded-icon-button";
import FaRight from '../img/font-awesome/regular/arrow-right.svg';
*/
import {graphql} from "gatsby";

import DownloadMacSafari from "../img/SVG/download-safari-mac.svg";
import DownloadMacChrome from "../img/SVG/download-chrome-mac.svg";
import DownloadMacFirefox from "../img/SVG/download-firefox-mac.svg";

import InstallGif from "../img/organizer/install-mac.gif";
import NonStretchedImage from '../components/non-stretchd-image';

const {detect} = require('detect-browser');


function justFileName(url)
{
    return url.match(/([^/]*)$/)[0];
}


class DownloadPageMac extends React.Component {

    constructor(props)
    {
        super(props);

        this.state = {
            fileName: justFileName(this.props.data.site.siteMetadata.downloadMac),
            browser: "safari"
        };
    }


    componentDidMount()
    {
        const browser = detect();

        this.setState({
            browser: browser.name
        });
    }


    render()
    {
        let text;

        if (this.state.browser === "chrome")
        {
            text = <>
                <p>
                    Please wait until the download is completed, then click on the
                    button with the text <b>{this.state.fileName}</b> in the bottom left corner of
                    this window.
                </p>
                <DownloadMacChrome style={{maxWidth: "30rem"}} className={'mx-auto block'}/>
            </>;
        }
        else if (this.state.browser === "firefox")
        {
            text = <>
                <p>
                    FireFox asks you what you want to do with the <b>{this.state.fileName}</b>.
                    Choose <b>Open with: DiskImageMounter (default)</b> and click <b>Ok</b>.
                    FireFox should automatically open the <a href="#installing">installer window</a>.
                </p>
                <p>
                    If this does not happen click on the little arrow at the top of this window:
                </p>
                <DownloadMacFirefox style={{maxWidth: "30rem"}} className={'mx-auto block'}/>
                <p>
                    Wait until the download is completed, then click the <b>{this.state.fileName}</b>.
                </p>
            </>;
        }
        else /* Safari */
        {
            text = <>
                <p>
                    Safari should start the download automatically. Click on the arrow
                    icon at the top of this window and wait until the download is
                    finished.
                </p>
                <DownloadMacSafari style={{maxWidth: "30rem"}} className={'mx-auto block'}/>
                <p>
                    Click on the <b>{this.state.fileName}</b> to continue with
                    the installation.
                </p>
            </>;
        }

        return (

            <Layout lang={'en'}
                    className={'nav--no_border'}
                    title={'2stitch Organizer - Free embroidery file viewer for Mac'}
                    description={'View, edit, convert or tag your embroidery files on your Mac. Supports: Brother (PES), Tajima (DST), Husquarna (HUS/VIP), Janome (JEF/SEW) and more.  '}
                    translated={{de: '/de/download-organizer-mac/'}}
                    location={this.props.location.pathname}
            >

                <div className={'bg-blue-50 text-blue-900 pb-12 pt-12 border-t border-b border-blue-900'}>
                    <div className="container mx-auto">
                        <AsyncDownload
                            url={this.props.data.site.siteMetadata.downloadMac}
                            productName={'2stitch Organizer'}
                        />
                    </div>
                </div>

                <div className={'container mx-auto mt-12 markdown'}>
                    <h2>Downloading 2stitch Organizer on your Mac</h2>

                    {text}

                    <h2 id="installing">Installing 2stitch Organizer on your Mac</h2>
                    <p>
                        You should see a window like the one below after a short time.
                        If this is not the case open your <b>Downloads</b> folder in Finder
                        and double-click <b>{this.state.fileName}</b>.
                    </p>

                    <p>
                        Drag the <b>2stitch Organizer.app</b> icon from the left
                        onto the <b>Application</b> folder to install it:
                    </p>

                    <div className="text-center">
                        <img src={InstallGif} alt={"How to install 2stitch Organizer on a Mac"}/>
                    </div>

                    <p>
                        2stitch Organizer is now installed. Open Finder and
                        navigate to the <b>Applications</b> folder. Double click the <b>2stitch Organizer</b> icon
                        to start it.
                    </p>

                    <p>macOS presents you with a warning that this application was downloaded from the internet from
                        www.codeandweb.com &mdash; this is ok. (CodeAndWeb is our company and we store the downloads
                        for 2stitch on the main page for simplicity). Simply click <b>Open</b>:
                    </p>

                    <div className="text-center">
                        <NonStretchedImage fluid={this.props.data.installMacWarningImage.childImageSharp.fluid}/>
                    </div>

                    {/*
                    <h2>Adding your designs</h2>

                    <p>
                        2stitch Organizer should now open and you should see the following screen after accepting
                        the license agreement. To add your designs folder you can either <b>drag &amp; drop the
                        folder onto the left side panel</b> &mdash; or click the <b>Add folder</b> button
                        and select the folder in the dialog.
                    </p>

                    <div className="text-center">
                        <Img fluid={this.props.data.firstStart.childImageSharp.fluid}/>
                    </div>
                    */}


                    {/*
                    <div className="text-center mt-8">
                        <RoundedIconButton to={"/organizer-getting-started"} icon={FaRight}>
                            Getting started
                        </RoundedIconButton>
                    </div>
                    */}
                </div>

            </Layout>
        )
            ;
    }
}

export default DownloadPageMac;

export const query = graphql`
  query DownloadPageMacQuery {
    site {
      siteMetadata {
        downloadMac
      }
    }

    installMacWarningImage: file(relativePath: { eq: "organizer/install-mac-warning.png" }) {
        childImageSharp {
            fluid(quality: 100, maxWidth:593) {
              ...GatsbyImageSharpFluid
              presentationWidth
        }
        }
    },

    firstStart: file(relativePath: { eq: "organizer/first-start-mac.png" }) {
        childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
              presentationWidth
        }
        }
    },
  }
`;
